import Picture3 from '../src/components/assets/img/img1.jpeg';



const HomeData = [
    {
        "Reportname": "Sample CFO Dashboard_Manufacturing",
        "ReportType": "Power BI",
        "Header":"CFO Reporting",
        "SubHeader":"Manufacturing Dashboard",
        "Reportbuiltby": "Saad",
        "Toollink": "https://app.powerbi.com/groups/204b6ccc-1739-4913-b57f-62e1d550dfba/reports/a587596e-87b9-4ee9-bf5e-553de19ab3ad/ReportSection17bf49ca0a814e78b802?experience=power-bi",
        "category": [
            "FP&A"
        ],
        "type":["Finance","Finance and Accounting","Procurement","Controllership"],
        "image": Picture3,
        "imageWidth": "40%",
        "description":"Sample CFO Dashboard_Manufacturing - last edit Naman Shri"

    },
    {
        "Reportname": "P&L MIS with Anomalies detection",
        "ReportType": "Power BI",
        "Header":"P&L Reporting",
        "SubHeader":"Flux Dashboard",
        "Reportbuiltby": "alex.logeais@effem.com",
        "Toollink": "https://app.powerbi.com/groups/204b6ccc-1739-4913-b57f-62e1d550dfba/reports/0fa8592d-2c0c-417e-9e62-733e7cb0c1ff/ReportSectiona53d24909b228731aa97?experience=power-bi",
        "category": [
            "Finance"
        ],
        "type":["Finance","Controllership","Internal Audit","Finance and Accounting"],
        "image": Picture3,
        "imageWidth": "40%",
        "description":"Analyze the quality of accounts over entire P&L with defined hierarchies upto GL level Raise red flag where there are anomalies to aid in better and faster closing of accounts by utilizing statistics and advanced analytics"
        

    },
    {
        "Reportname": "IVA genAI tool",
        "ReportType": "Tool",
        "Header":"P&L Reporting",
        "SubHeader":" GenAI Tool",
        "Reportbuiltby": "alex.logeais@effem.com",
        "Toollink": "https://www.ifa.aaysanalytics.com/",
        "category": [
            "Finance","supply_chain"
        ],
        "image": Picture3,
        "type":["Finance","Finance and Accounting","Internal Audit","Generative AI"],
        "imageWidth": "40%",
        "description":"Deployed AI powered reasoning & smart insights engine to provide"

    },
    {
        "Reportname": "Accounts Payable Overview",
        "Header":"Working capital Optimisation",
        "SubHeader":"Accounts Payable",
        "ReportType": "Power BI",
        "Reportbuiltby": "alex.logeais@effem.com,dylan.murray@effem.com,john.leamon@effem.com",
        "Toollink": "https://app.powerbi.com/groups/204b6ccc-1739-4913-b57f-62e1d550dfba/reports/3d4c44d1-92bc-42bb-96b5-962cc685f4b4/ReportSectionfdd69a427474caf56f83?experience=power-bi",
        "category": [
            "Finance"
        ],
        "type":["Accounts Payable","Finance","Vendor Payments","Procurement"],
        "image": Picture3,
        "imageWidth": "40%",
        "description":"This report provides an overview of Accounts payable at an organisation level. Provides breakdown of Outstanding v/s overview, purchase trends on monthly basis, purchase trends by vendor, instances of early payments / late payments etc. "
    },
    {
        "Reportname": "Cash AI forecast",
        "ReportType": "Power BI",
        "Header":"Treasury",
        "SubHeader":"Cash AI forecast",
        "Reportbuiltby": "alex.logeais@effem.com",
        "Toollink": "https://app.powerbi.com/groups/204b6ccc-1739-4913-b57f-62e1d550dfba/reports/d6d4f943-3271-429c-8f1b-99c82e05088e/ReportSectiondc65497d54f4f749b395?experience=power-bi",
        "category": [
            "Finance","FP&A"
        ],
        "image": Picture3,
        "type":["Finance","Cash Flow","Treasury","Finance and Accounting"],
        "imageWidth": "40%",
        "description":"AI based short term cash flow forecast for treasury team"

    },
    {
        "Reportname": "Daily Sales report",
        "ReportType": "Power BI",
        "Header":"CFO Reportng",
        "SubHeader":"Daily Sales report",
        "Reportbuiltby": "alex.logeais@effem.com",
        "Toollink": "https://app.powerbi.com/groups/204b6ccc-1739-4913-b57f-62e1d550dfba/reports/e3a40a24-b37f-4ca4-a2bb-27762c256509/ReportSectionae48c76faee406596c18?experience=power-bi",
        "category": [
            "FP&A"
        ],
        "type":["Finance","Sales","Finance and Accounting","Cash Flow"],
        "image": Picture3,
        "imageWidth": "40%",
        "description":"Advanced forecasting at a customer level and normalise trade expenditures to estimate NSV"

    },
    {
        "Reportname": "Vendor Payments - ML Accelerator - 1",
        "ReportType": "Power BI",
        "Header":"Procure to Pay",
        "SubHeader":"AI Accelerator",
        "Reportbuiltby": "alex.logeais@effem.com",
        "Toollink": "https://app.powerbi.com/groups/204b6ccc-1739-4913-b57f-62e1d550dfba/reports/25a5c182-d7c5-43cb-b892-c566ae2959de/ReportSection8d352e0db670eb1be96d?experience=power-bi",
        "category": [
            "FP&A"
        ],
        "type":["Finance","Procurement","Accounts Payable","Machine Learning","Regression"],
        "image": Picture3,
        "imageWidth": "40%",
        "description":"Monitor “Early payment instances” and provide ML based specific triggers at an invoice level to reduce future occurrences and optimise cash flow"

    },
    {
        "Reportname": "Vendor Payments - ML Accelerator - 2",
        "ReportType": "Power BI",
        "Header":"Procure to Pay",
        "SubHeader":"AI Accelerator 2",
        "Reportbuiltby": "alex.logeais@effem.com",
        "Toollink": "https://app.powerbi.com/groups/204b6ccc-1739-4913-b57f-62e1d550dfba/reports/25a5c182-d7c5-43cb-b892-c566ae2959de/ReportSection8d352e0db670eb1be96d?experience=power-bi",
        "category": [
            "FP&A"
        ],
        "type":["Finance","Accounts Payable","Procurement","Machine Learning"],
        "image": Picture3,
        "imageWidth": "40%",
        "description":"Compare payment terms within a specific category across multiple vendors and provide ML based clusters to optimise future cash flow"

    },
    {
        "Reportname": "Raws & Packs visibility",
        "ReportType": "Power BI",
        "Header":"P&L Reporting",
        "SubHeader":"Prime Spend",
        "Reportbuiltby": "alex.logeais@effem.com",
        "Toollink": null,
        "category": [
            "FP&A"
        ],
        "type":["Finance","Prime Spend","Finance and Accounting","Inventory Management"],
        "image": Picture3,
        "imageWidth": "40%",
        "description":"Provides inventories of Raw materials, packing materials etc. across different plants etc."

    },
    {
        "Reportname": "Inventory Finance insights",
        "ReportType": "Power BI",
        "Header":"Working Capital Optimisation",
        "SubHeader":"Inventory insights",
        "Reportbuiltby": "alex.logeais@effem.com",
        "Toollink": "https://app.powerbi.com/groups/204b6ccc-1739-4913-b57f-62e1d550dfba/reports/349a97bb-586a-42f7-9031-eec7f709d095/ReportSectione5d78148506a8e662bd5?experience=power-bi",
        "category": [
            "Finance"
        ],
        "type":["Finance","Inventory Management","Finance and Accounting"],
        "image": Picture3,
        "imageWidth": "40%",
        "description":"Inventory insights - understock vs overstock, safety stock, coverage etc."

    },
    {
        "Reportname": "Inventory Categorisation and action to drive",
        "ReportType": "Power BI",
        "Header":"Working Capital Optimisation",
        "SubHeader":"Inventory classification",
        "Reportbuiltby": "Saad",
        "Toollink": "https://app.powerbi.com/groups/204b6ccc-1739-4913-b57f-62e1d550dfba/reports/ef8482c2-2f6c-49b1-a405-2c54fd60b66b/ReportSection855b989661087072d66e?experience=power-bi",
        "category": [
            "FP&A"
        ],
        "type":["Inventory Classification","Finance and Accounting","Compliance Reporting"],
        "image": Picture3,
        "imageWidth": "40%",
        "description":"Create Inventory category and actionable insights to understand profit metrics."

    },
    {
        "Reportname": "Supply Chain Risk resiliency tool",
        "ReportType": "Power BI",
        "Header":"Supply Chain",
        "SubHeader":"Risk resiliency",
        "Reportbuiltby": "Saad",
        "Toollink": "https://app.powerbi.com/groups/204b6ccc-1739-4913-b57f-62e1d550dfba/reports/0a380e16-dadc-4937-9ed9-f1cdb409e6fc/ReportSection9fd8e72c671ad8e79fe9?experience=power-bi",
        "category": [
            
            "supply_chain"
        ],
        "type":["Supply Chain","Finance and Accounting","Generative AI"],

        "image": Picture3,
        "imageWidth": "40%",
        "description":"Vendor Risk Management,Supply Chain Risk resiliency tool"

    },
    {
        "Reportname": "Supply Chain Risk resiliency tool",
        "ReportType": "Applications",
        "Header":"Supply Chain",
        "SubHeader":"Risk resiliency",
        "Reportbuiltby": "Saad",
        "Toollink": "https://www.riskresilence.aaysanalytics.com/",
        "category": [
            
            "supply_chain"
        ],
        "type":["Supply Chain","Finance and Accounting","Generative AI"],

        "image": Picture3,
        "imageWidth": "40%",
        "description":"Vendor Risk Management,Supply Chain Risk resiliency tool"

    },
    {
        "Reportname": "Manual adjustments/ Manual journal entries",
        "ReportType": "Power BI",
        "Header":"Record to Report",
        "SubHeader":"Manual Journal entries",
        "Reportbuiltby": "alex.logeais@effem.com",
        "Toollink": "https://app.powerbi.com/groups/204b6ccc-1739-4913-b57f-62e1d550dfba/reports/c339eba0-dcec-47c8-b82f-17f051ae79b2/ReportSection643178a61da3b7a068a0?experience=power-bi",
        "category": [
            "Process_Compliance"
        ],
        "type":["Finance and Accounting","Finance","Record to Report","Process Analytics"],
        "image": Picture3,
        "imageWidth": "40%",
        "description":"Manual journey entries"

    },
   
    {
        "Reportname": "End to end Inventory - Demand and Supply",
        "ReportType": "Power BI",
        "Header":"Supply Chain",
        "SubHeader":"Inventory visibility & forecasting",
        "Reportbuiltby": "Saad",
        "Toollink": "https://app.powerbi.com/groups/204b6ccc-1739-4913-b57f-62e1d550dfba/reports/e0035cd4-223b-4942-a033-5ad4e4330e3a/ReportSection8168c2100b0814ab0579?experience=power-bi",
        "category": [
            "supply_planning"
        ],
        "type":["Inventory Classification","Finance and Accounting","Compliance Reporting"],
        "image": Picture3,
        "imageWidth": "40%", 
        "description":"Provides market wide inventory requirements, projections and demand-supply situation, over stock / under stock scenarios etc."

    },
    {
        "Reportname": "Payment Term – Cash Impact",
        "ReportType": "Power BI",
        "Header":"Working Capital Optimisation",
        "SubHeader":"Vendor Payment Terms",
        "Reportbuiltby": "Saad",
        "Toollink": "https://app.powerbi.com/groups/204b6ccc-1739-4913-b57f-62e1d550dfba/reports/416e1d0b-dd18-4022-bca9-c526c54a4121/ReportSection?experience=power-bi",
        "category": [
            "FP&A","Process_Compliance"
        ],
        "type":["Finance","Inventory Management","Procurement","Finance and Accounting"],
        "image": Picture3,
        "imageWidth": "40%",
        "description":"Working Capital Optimisation and monitoring"

    },
    {
        "Reportname": "Nutrition Reporting",
        "ReportType": "Power BI",
        "Header":"Sales reporting",
        "SubHeader":"Compliance Reporting",
        "Reportbuiltby": "alex.logeais@effem.com",
        "Toollink": "https://app.powerbi.com/groups/204b6ccc-1739-4913-b57f-62e1d550dfba/reports/7999504c-927c-4ffa-b863-116cbd5d3550/ReportSection55d77d0e8d624dd4e5ef?experience=power-bi",
        "category": [
            "Process_Compliance"
        ],
        "image": Picture3,
        "type":["Compliance Reporting","Finance and Accounting","Inventory Management"],
        "imageWidth": "40%",
        "description":"Trace change of formulations and evolving customer preferences on health & nutritional parameters"

    },
  
    {
        "Reportname": "Accounts Receivable Overview",
        "ReportType": "Power BI",
        "Header":"Working Capital Optimisation",
        "SubHeader":"Accounts Receivable",
        "Reportbuiltby": "Saad",
        "Toollink": "https://app.powerbi.com/groups/204b6ccc-1739-4913-b57f-62e1d550dfba/reports/c3cac72b-36ce-4e6e-9b3b-b096084fa6b2/ReportSectionfdd69a427474caf56f83?experience=power-bi",
        "category": [
            "Finance"
        ],
        "type":["Finance","Finance and Accounting","Record to Report"],
        "image": Picture3,
        "imageWidth": "40%",
        "description":"Overview and summary of accounts receivable"

    },
    {
        "Reportname": "Advanced Analytics on Accounts Receivable",
        "ReportType": "Power BI",
        "Header":"Order to Cash",
        "SubHeader":"AI accelerator",
        "Reportbuiltby": "alex.logeais@effem.com",
        "Toollink": null,
        "category": [
            "FP&A"
        ],
        "type":["Compliance Reporting","Finance and Accounting","Inventory Management"],
        "image": Picture3,
        "imageWidth": "40%",
        "description":"Identify root casue for customer payment delays and cluster similar sized customers with delayed payments and optimise working capital"

    },
    {
        "Reportname": "Innovation Analytics",
        "ReportType": "Power BI",
        "Header":"Sales & New Product Reporting",
        "SubHeader":"New Launch Analytics",
        "Reportbuiltby": "William",
        "Toollink": "https://app.powerbi.com/groups/204b6ccc-1739-4913-b57f-62e1d550dfba/reports/df7e2428-309b-4e5b-96fe-0834fd16dbd5/ReportSection?experience=power-bi",
        "category": [
            "CS&L"
        ],
        "type":["Compliance Reporting","Finance and Accounting","Inventory Management"],
        "image": Picture3,
        "imageWidth": "40%",
        "description":"Track new launches performance and incrementality of new launches adjusting for cannabalization"

    },
   
   
    {
        "Reportname": "Customer Service dashboards",
        "ReportType": "Power BI",
        "Header":"Sales & Customer Service",
        "SubHeader":"Dashboard",
        "Reportbuiltby": "Saad",
        "Toollink": "https://app.powerbi.com/groups/204b6ccc-1739-4913-b57f-62e1d550dfba/reports/52ecbe1d-78d8-43bb-9f7e-9caafc3643af/ReportSectionc5496318ebee78de3bae?experience=power-bi",
        "category": [
            "CS&L",
            "supply_planning"
        ],
        "type":["Compliance Reporting","Finance and Accounting","Inventory Management"],

        "image": Picture3,
        "imageWidth": "40%",
        "description":null

    },
    {
        "Reportname": "Vendor Risk Management",
        "ReportType": "Applications",
        "Header":"Supply chain",
        "SubHeader":"Vendor Risk management",
        "Reportbuiltby": "Saad",
        "Toollink": 'https://aays-risk-management.aaysanalytics.com/',
        "category": [
            "Process_Compliance",
            "supply_chain"
        ],
        "type":["Supply Chain","Process Compliance","Internal Audit","Supply Chain"],

        "image": Picture3,
        "imageWidth": "40%",
        "description":"Vendor Risk Management, Supply Chain Risk resiliency tool"

    },
    {
        "Reportname": "Market mix Modelling",
        "ReportType": "Power BI",
        "Header":"Sales & Marketing",
        "SubHeader":"Market Mix Modelling",
        "Reportbuiltby": "Saad",
        "Toollink": "https://app.powerbi.com/groups/204b6ccc-1739-4913-b57f-62e1d550dfba/reports/9b2e53e2-dff8-41d9-a0d8-61a561ba6d7f/ReportSection?experience=power-bi",
        "category": [
            "CS&L",
            "supply_planning"
        ],
        "type":["Compliance Reporting","Finance and Accounting","Inventory Management"],

        "image": Picture3,
        "imageWidth": "40%",
        "description":null

    },
    {
        "Reportname": "OTC Customer segmentation and Bechmarking",
        "ReportType": "Power BI",
        "Header":"Order to Cash",
        "SubHeader":"Customer Segmentation",
        "Reportbuiltby": "Saad",
        "Toollink": null,
        "category": [
            "CS&L",
            "supply_planning"
        ],
        "type":["Compliance Reporting","Finance and Accounting","Inventory Management"],

        "image": Picture3,
        "imageWidth": "40%",
        "description":"Identify opportunities of efficiency improvement by benchmarking customers within a specific cluster/group."

    },
    {
        "Reportname": "Social Media Analyer",
        "ReportType": "Tool",
        "Header":"Marketing",
        "SubHeader":"Social Media Analyzer",
        "Reportbuiltby": "Saad",
        "Toollink": "https://social-media-tool.streamlit.app/",
        "category": [
            "CS&L",
            "supply_planning"
        ],
        "type":["Compliance Reporting","Finance and Accounting","Inventory Management"],

        "image": Picture3,
        "imageWidth": "40%",
        "description":"Topic modelling and understand marketing mix from social media conversations"

    },
    {
        "Reportname": "Receipe Reporting",
        "ReportType": "Power BI",
        "Header":"Operations",
        "SubHeader":"Recipe reporting",
        "Reportbuiltby": "Saad",
        "Toollink": null,
        "category": [
            "Process_Compliance"
        ],
        "type":["Process Compliance","Finance and Accounting","Record to Report"],

        "image": Picture3,
        "imageWidth": "40%",
        "description":null

    },
    {
        "Reportname": "Laboratory Analytics",
        "ReportType": "Power BI",
        "Header":"Operations",
        "SubHeader":"Lab & R&D",
        "Reportbuiltby": "Saad",
        "Toollink": null,
        "category": [
            "Process Compliance"
        ],
        "type":["Compliance Reporting","Finance and Accounting","Inventory Management"],

        "image": Picture3,
        "imageWidth": "40%",
        "description":null

    },
    {
        "Reportname": "P&L MIS with Anomalies detection with external factors",
        "ReportType": "Power BI",
        "Header":"P&L reporting",
        "SubHeader":"Flux reporting advanced",
        "Reportbuiltby": "Saad",
        "Toollink": "https://app.powerbi.com/groups/204b6ccc-1739-4913-b57f-62e1d550dfba/reports/0fa8592d-2c0c-417e-9e62-733e7cb0c1ff/ReportSectiona53d24909b228731aa97?experience=power-bi",
        "category": [
            "Finance"
        ],
        "type":["Compliance Reporting","Finance and Accounting","Inventory Management"],
        "image": Picture3,
        "imageWidth": "40%",
        "description":"Analyze the quality of accounts over entire P&L with defined hierarchies upto GL level Raise red flag where there are anomalies to aid in better and faster closing of accounts by utilizing statistics and advanced analytics - including impact of external factors"

    },
  
    {
        "Reportname": "Social Listening -Auto brands",
        "ReportType": "Tool",
        "Header":"Marketing",
        "SubHeader":"Social Listening",
        "Reportbuiltby": "Saad",
        "Toollink": "https://testauto.streamlit.app/",
        "category": [
            "supply_planning"
        ],
        "type":["Compliance Reporting","Finance and Accounting","Inventory Management"],
        "image": Picture3,
        "imageWidth": "40%",
        "description":"Streamlit App, source code to be connected later. (DP)"

    },
   
   
]

export default HomeData;